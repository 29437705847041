import {PatriciaWorking, PatanCity} from '../assets/images';
import ContentDialog from "./ContentDialog";

const about = {
    patan: {
        title: "Wie is Patan?",
        short: "Patan is in 2007 ontstaan uit de samenwerking tussen Patricia Ohlsen en Antoinette van der Spiegel. Sinds 2013 is Patricia alleen verder gegaan met Patan. Behalve de samenvoeging van de twee namen is Patan een koningsstad in Nepal. Deze \"stad der schoonheid\" ligt in de Kathmanduvallei. In de hoge bergpieken van de Himalaya wonen trotse bevolkingsgroepen. Ze staan bekend als krijgshaftige, creatieve en vakkundige lieden.",
        description: `Patan is in 2007 ontstaan uit de samenwerking tussen Patricia Ohlsen en Antoinette van der Spiegel. Sinds 2013 is Patricia alleen verder gegaan met Patan. Behalve de samenvoeging van de twee namen is Patan een koningsstad in Nepal. Deze "stad der schoonheid" ligt in de Kathmanduvallei. In de hoge bergpieken van de Himalaya wonen trotse bevolkingsgroepen. Ze staan bekend als krijgshaftige, creatieve en vakkundige lieden.
        <br/><br/>
        En dat is wat we met Patan ook willen bewerkstelligen. Door middel van coaching, training en advies helpen we mensen die hoge bergen (knelpunten, dilemma's, belemmerende overtuigingen) te overwinnen, door te werken aan zelfvertrouwen, inzicht en vaardigheden. Daar gebruiken we o.a. creatieve methoden en werkvormen bij en vragen we van de mensen met wie we werken om creatief en buiten kaders te denken, net als de bevolking van Patan. Zo werken we aan een balans in het werk en privéleven en streven we ernaar dat je in je kracht komt te staan.
        <br/><br/>
        Daarnaast moet je vakkundig zijn om je te kunnen inzetten voor de aanpak van kindermishandeling en huiselijk geweld. Daarvoor is het nodig dat je in gesprek gaat met elkaar en dat er constructief samengewerkt wordt. Met onze trainingen en projecten die we uitvoeren proberen we daar een positieve bijdrage aan te leveren.`
    },
    ik: {
        title: "Wie ben ik?",
        short: "Patricia Ohlsen (1970) is afgestudeerd als pedagoog aan de Rijkuniversiteit Leiden, opleiding gezinspedagogiek. Ze heeft 5 jaar in de kinderopvang gewerkt, zowel als leidster als leidinggevende. In de tussentijd heeft ze een managementopleiding gevolgd. Vervolgens heeft ze 10 jaar bij JSO, een expertisecentrum voor jeugd, samenleving en opvoeding gewerkt.",
        description: `Patricia Ohlsen (1970) is afgestudeerd als pedagoog aan de Rijkuniversiteit Leiden, opleiding gezinspedagogiek. Ze heeft 5 jaar in de kinderopvang gewerkt, zowel als pedagogisch medewerker als leidinggevende. In de tussentijd heeft ze een managementopleiding gevolgd. Vervolgens heeft ze 10 jaar bij JSO, een expertisecentrum voor jeugd, samenleving en opvoeding gewerkt. Daar is ze achtereenvolgens projectleider, regiocoördinator/ trainer preventie kindermishandeling en clustermanager geweest. Na twee jaar e-learningmodules over kindermishandeling en de meldcode te hebben ontwikkeld bij de Augeo Foundation, heeft zij anderhalf jaar gewerkt als Regiocoördinator RAAK (regionale aanpak kindermishandeling) bij de GGD Rotterdam Rijnmond.
        <br/><br/>
        In 2007 heeft zij haar opleiding tot beroepscoach afgerond en is haar eigen bureau gestart. In 2014 is zij afgestudeerd als Mediator en in 2015 heeft zij de specialisatie tot familiemediator afgerond. Vervolgens heeft zij in 2018 de opleiding tot transformatief trainer en coach en in 2019 systemisch werken afgerond.
        <br/><br/>
        Ze wordt ingehuurd als beleidsadviseur, trainer en coach door instellingen als gemeenten, GGD, Veilig Thuisorganisaties, jeugdgezondheidszorg, onderwijs en kinderopvang e.d. Haar expertise ligt in het begeleiden en trainen van teams en individuen die te maken hebben (gehad) met kindermishandeling, huiselijk geweld, loverboys, ongewenste omgangsvormen, communicatie- en samenwerkingsproblemen. Ze is gespecialiseerd in het geven van communicatietrainingen op het gebied van zorgen delen met ouders bij een vermoeden van kindermishandeling of huiselijk geweld. Ook geeft ze trainingen over het implementeren van de meldcode kindermishandeling en huiselijk geweld, aanpak huiselijk geweld en kindermishandeling en veiligheidsplanning.`
    }
}

export default function AboutTestimonial() {
    return (
        <div className="relative pt-16 pb-32 overflow-hidden" id="wie-is-patan">
            <div aria-hidden="true" className="absolute inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-100"/>
            <div className="relative">
                <div
                    className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                    <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
                        <div>
                            <div className="mt-6">
                                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                                    {about.patan.title}
                                </h2>
                                <div className="mt-4 text-lg text-gray-500">
                                    {about.patan.short}
                                    <ContentDialog title={about.patan.title} description={about.patan.description} size="sm:max-w-6xl" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-12 sm:mt-16 lg:mt-0">
                        <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                            <img
                                className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                                src={PatanCity}
                                alt="Foto van de stad Patan"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-24">
                <div
                    className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                    <div
                        className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
                        <div>
                            <div className="mt-6">
                                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                                    {about.ik.title}
                                </h2>
                                <div className="mt-4 text-lg text-gray-500">
                                    {about.ik.short}
                                    <ContentDialog title={about.ik.title} description={about.ik.description} size="sm:max-w-6xl"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
                        <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                            <img
                                className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                                src={PatriciaWorking}
                                alt="Foto van Patricia Ohlsen"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}