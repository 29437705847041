import {Link} from "react-router-dom";

export default function MenuCloud(props) {

    const { cloudNav } = props;

    return (
        <div className="" id="aanbod">
            <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
                <div className="grid grid-cols-1 gap-8 md:grid-cols-6 lg:grid-cols-5">
                    {cloudNav.map((child) => {
                        return (
                            <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1" key={child.name}>
                                <Link to={child.href}
                                      className="text-red-500 font-bold text-2xl px-4 py-3 hover:text-white hover:bg-red-500 rounded-lg transition">
                                    {child.name}
                                </Link>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    )
}