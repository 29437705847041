import {lazy, Suspense} from "react";
import MenuCloud from "./components/MenuCloud";
import AboutTestimonial from "./components/AboutTestimonial";
const Tabs = lazy(() => import("./components/Tabs"));
const Contact = lazy(() => import("./components/Contact"));
const Publicaties = lazy(() => import("./components/Publicaties"));

export default function Home(props) {
    const { cloudNav } = props;
    return(
        <Suspense fallback={<div>Loading...</div>}>
            <MenuCloud cloudNav={cloudNav} />
            <AboutTestimonial />
            <Publicaties />
            <Tabs>
                <div label="Algemeen">
                    <ul className="list-square">
                        <li>JSO</li>
                        <li>The Next Page / Augeo</li>
                        <li>Rijksuniversiteit Leiden</li>
                        <li>Stichting JES Nederland</li>
                        <li>Dessaur Trainingen</li>
                        <li>Anja Roosendaal Training, Coaching en advies</li>
                        <li>Kruijs Training en Advies</li>
                        <li>Stade Advies</li>
                        <li>Medilex</li>
                        <li>Euregionaal Congresbureau</li>
                        <li>Vulkaan</li>
                    </ul>
                </div>
                <div label="Gemeenten">
                    <ul className="list-square">
                        <li>Albrandswaard</li>
                        <li>Capelle aan den Ijssel</li>
                        <li>Hellevoetsluis</li>
                        <li>Krimpen aan den Ijssel</li>
                        <li>Lansingerland</li>
                        <li>Gouda</li>
                        <li>Leiden</li>
                        <li>Rotterdam</li>
                        <li>Zwijndrecht</li>
                        <li>Den Haag</li>
                    </ul>
                </div>
                <div label="Kinderopvang">
                    <ul className="list-square">
                        <li>JSO</li>
                        <li>Medilex (KO)</li>
                        <li>SZK Kinderopvang</li>
                        <li>2-Samen</li>
                        <li>Kinderopvang Bijdehand</li>
                        <li>Stichting Kinderopvang Zoeterwoude</li>
                        <li>BSO de Harlekijn</li>
                        <li>Gastouderbureau Zoetermeer</li>
                    </ul>
                </div>
                <div label="Gezondheidszorg">
                    <ul className="list-square">
                        <li>WOKK (kinderartsen, SEH, Jeugdartsen): Stichting Spoedeisende Hulp voor kinderartsen (SSHK)</li>
                        <li>GGD HM (jeugdartsen, jeugdverpleegkundigen, forensisch artsen, logopedisten, cb-assistenten,
                            klantcontactcentrum)</li>
                        <li>GGZ Rivierduinen</li>
                        <li>Centrum voor Autisme</li>
                        <li>Kristal</li>
                        <li>PsyQ Hilversum</li>
                        <li>Florence Jong (kinderverpleegkundigen)</li>
                        <li>Activite</li>
                        <li>WIJdezorg</li>
                        <li>Zorg-Vuldig (kraamzorg)</li>
                        <li>De Binnenvest (maatschappelijk werkers en vrijwilligers, dak en thuislozenzorg)</li>
                        <li>Medisch Centrum Haaglanden (SEH)</li>
                        <li>HagaAcademie (SEH)</li>
                        <li>Reinier de Graaf ziekenhuis</li>
                        <li>Hogeschool Leiden (opleiding verpleegkundigen)</li>
                        <li>Lange Land ziekenhuis</li>
                        <li>St Jans Gasthuis Weert</li>
                        <li>CJG Capelle aan den IJssel (begeleide intervisie)</li>
                    </ul>
                </div>
                <div label="Onderwijs">
                    <ul className="list-square">
                        <li>KPC groep (vertrouwenspersonen)</li>
                        <li>SPCO Groene Hart (vertrouwenspersonen)</li>
                        <li>SKVOB (vertrouwenspersonen)</li>
                        <li>Spijtenburg (PO, VO, MBO in het kader van vertrouwenspersonen)</li>
                        <li>Spirit basisscholen en OOP, nu Florente (vertrouwenspersonen)</li>
                        <li>GGD Flevoland (vertrouwenspersonen en directeuren PO en VO)</li>
                        <li>IVO-Deurne </li>
                        <li>Medilex (PO, VO)</li>
                        <li>Basisschool de Paradijsvogel</li>
                        <li>Basisschool het Podium</li>
                        <li>Basisschool de Weerklank</li>
                        <li>PO Blokhuus</li>
                        <li>Willibrordschool</li>
                    </ul>
                </div>
                <div label="Veilig Thuis">
                    <ul className="list-square">
                        <li>VNG</li>
                        <li>Veilig Thuis Hollands Midden</li>
                        <li>Veilig Thuis Zuid Limburg</li>
                        <li>Veilig Thuis Noord en Midden Limburg</li>
                        <li>Veilig Thuis Gelderland Zuid</li>
                        <li>Veilig Thuis IJsselland</li>
                        <li>Veilig Thuis Zeeland</li>
                        <li>Veilig Thuis Groningen</li>
                        <li>Veilig Thuis Zaanstreek-Waterland</li>
                        <li>Veilig Thuis Gooi en Vechtstreek</li>
                        <li>Veilig Thuis Haaglanden</li>
                        <li>Veilig Thuis Rotterdam</li>
                        <li>Veilig Thuis Flevoland</li>
                        <li>Veilig Thuis Midden Brabant</li>
                        <li>Veilig Thuis Zuid Oost Brabant</li>
                        <li>Veilig Thuis Kennemerland</li>
                    </ul>
                </div>
            </Tabs>
            <Contact/>
        </Suspense>
    )
}