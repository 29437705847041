function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function ImageHeader(props) {
    const {image, text, imagePos} = props;

    return (
        <div className="relative bg-red-100">
            <div className="absolute inset-0">
                <img
                    className={classNames(imagePos, "w-full h-full object-cover")}
                    src={image}
                    alt={text}
                />
                <div className="absolute inset-0 bg-red-100 mix-blend-multiply" aria-hidden="true" />
            </div>
            <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
                <p className="mt-6 text-xl text-white text-center">
                    {text}
                </p>
            </div>
        </div>
    )
}