import { Logo } from '../assets/images';
import {Link} from "react-router-dom";

export default function NotFound() {
    return (
        <div className="pt-16 pb-12 flex flex-col bg-white">
            <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex-shrink-0 flex justify-center">
                    <a href="/" className="inline-flex">
                        <span className="sr-only">Patan Coaching en Training</span>
                        <img
                            className="h-12 w-auto"
                            src={Logo}
                            alt="Patan logo"
                        />
                    </a>
                </div>
                <div className="py-16">
                    <div className="text-center">
                        <p className="text-sm font-semibold text-red-600 uppercase tracking-wide">404 error</p>
                        <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">Pagina niet gevonden.</h1>
                        <p className="mt-2 text-base text-gray-500">Sorry, we konden de pagina die je zoekt niet vinden.</p>
                        <div className="mt-6">
                            <Link to="/" className="text-base font-medium text-red-600 hover:text-red-500">
                                Ga terug naar de homepagina<span aria-hidden="true"> &rarr;</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}