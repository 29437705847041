import {lazy, Suspense} from "react";
import './App.css';
import {
    Switch,
    Route
} from "react-router-dom";
import Header from "./components/Header";
import Home from "./Home";
import OfferNav from "./components/OfferNav";
import ImageHeader from "./components/ImageHeader";
import {Helmet} from "react-helmet";
import {
    Squirrel,
    Butterfly,
    Dunes,
    Mountain,
    River
} from "./assets/images"
import NotFound from "./components/NotFound";

const Footer = lazy(() => import("./components/Footer"));
const Coaching = lazy(() => import("./components/Coaching"));
const Trainingen = lazy(() => import("./components/Trainingen"));
const Advies = lazy(() => import("./components/Advies"));
const Projecten = lazy(() => import("./components/Projecten"));
const Mediation = lazy(() => import("./components/Mediation"));


const navigation = [
    {name: 'Aanbod', href: '/#aanbod'},
    {name: 'Wie is Patan', href: '/#wie-is-patan'},
    {name: 'Portfolio', href: '/#portfolio'},
    {name: 'Contact', href: '/#contact'},
];

const cloudNav = [
    {name: 'Coaching', href: '/coaching'},
    {name: 'Trainingen', href: '/trainingen'},
    {name: 'Advies & Beleid', href: '/advies'},
    {name: 'Projecten', href: '/projecten'},
    {name: 'Mediation', href: '/mediation'},
];

const LoadingComponent = () => (<div>Loading...</div>);

function App() {
    return (
        <div>
            <Switch>
                <Route path="/coaching">
                    <Helmet>
                        <title>Coaching | Patan Coaching en Training</title>
                        <meta name="description" content="Coaching aanbod van Patan" />
                        <link rel="canonical" href="https://patan.nu/coaching" />
                    </Helmet>
                    <OfferNav navigation={cloudNav} />
                    <ImageHeader image={Squirrel} text="Coaching is gericht op iemands mogelijkheden, niet op zijn beperkingen." imagePos="object-top" />
                    <Suspense fallback={<LoadingComponent />}>
                        <Coaching />
                    </Suspense>
                </Route>
                <Route path="/trainingen">
                    <Helmet>
                        <title>Training | Patan Coaching en Training</title>
                        <meta name="description" content="Training aanbod van Patan" />
                        <link rel="canonical" href="https://patan.nu/trainingen" />
                    </Helmet>
                    <OfferNav navigation={cloudNav} />
                    <ImageHeader image={Butterfly} text="Je kunt de situatie niet altijd beïnvloeden, maar wel HOE je er mee omgaat." />
                    <Suspense fallback={<LoadingComponent />}>
                        <Trainingen />
                    </Suspense>
                </Route>
                <Route path="/advies">
                    <Helmet>
                        <title>Advies | Patan Coaching en Training</title>
                        <meta name="description" content="Advies en beleid aanbod van Patan" />
                        <link rel="canonical" href="https://patan.nu/advies" />
                    </Helmet>
                    <OfferNav navigation={cloudNav} />
                    <ImageHeader image={Dunes} text="Het mooie van een doodlopende weg is: hij dwingt ons een andere weg te kiezen." />
                    <Suspense fallback={<LoadingComponent />}>
                        <Advies />
                    </Suspense>
                </Route>
                <Route path="/projecten">
                    <Helmet>
                        <title>Projecten | Patan Coaching en Training</title>
                        <meta name="description" content="Projecten aanbod van Patan" />
                        <link rel="canonical" href="https://patan.nu/projecten" />
                    </Helmet>
                    <OfferNav navigation={cloudNav} />
                    <ImageHeader image={Mountain} text='"Alone we can do so litte; together we can do so much." - Hellen Keller' />
                    <Suspense fallback={<LoadingComponent />}>
                        <Projecten />
                    </Suspense>
                </Route>
                <Route path="/mediation">
                    <Helmet>
                        <title>Mediation | Patan Coaching en Training</title>
                        <meta name="description" content="Mediation aanbod van Patan" />
                        <link rel="canonical" href="https://patan.nu/mediation" />
                    </Helmet>
                    <OfferNav navigation={cloudNav} />
                    <ImageHeader image={River} text="Het kost niets om respectvol met een ander om te gaan." />
                    <Suspense fallback={<LoadingComponent />}>
                        <Mediation />
                    </Suspense>
                </Route>
                <Route exact path="/">
                    <Helmet>
                        <title>Patan Coaching en Training</title>
                        <meta name="description" content="Patan is een kleinschalig coachings- en trainingsbureau. Ons uitgangspunt is het vergroten van effectiviteit en kwaliteit van het functioneren van individuen en teams. We zijn gespecialiseerd in de aanpak van huiselijk geweld en kindermishandeling en communicatietrainingen." />
                        <link rel="canonical" href="https://patan.nu/" />
                    </Helmet>
                    <Header navigation={navigation} />
                    <Home cloudNav={cloudNav} />
                </Route>
                <Route path="*" >
                    <Helmet>
                        <title>404 Pagina niet gevonden | Patan Coaching en Training</title>
                    </Helmet>
                    <NotFound />
                </Route>
            </Switch>
            <Suspense fallback={<LoadingComponent />}>
                <Footer/>
            </Suspense>
        </div>
    );
}

export default App;
